@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.ql-toolbar {
    @apply rounded-t-md;
}
.ql-container {
    @apply rounded-b-md overflow-auto;
    min-height: 300px;
    max-height: 400px;
}
.mx-datepicker{
    width: 100% !important;
}

.line:after {
    content: "";
    @apply h-full w-full absolute top-0 left-0 border-t border-gray-200;
}

.line-up:after {
    transform: skewY(-6deg);
}

.line-down:after {
    transform: skewY(6deg);
}

.fade-enter-active, .fade-leave-active {
    @apply transition-opacity duration-150
}
.fade-enter, .fade-leave-to {
    @apply opacity-0
}

.slide-right-enter-active, .slide-right-leave-active {
    transition: transform .15s;
    transform: translateX(0);
    /*@apply transition-transform translate-x-0 duration-100*/
}
.slide-right-enter , .slide-right-leave-to {
    transform: translateX(100%);
    /*@apply translate-x-full*/
}

.with-overlay {
    @apply relative;
}

.with-overlay::after {
    content: '';
    @apply absolute inset-0 bg-black bg-opacity-40;
}
